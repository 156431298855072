<template>
    <article class="full-article">
        <a href="#" class="back" @click.prevent="$router.go(-1)">Tillbaka</a>
        <img v-if="article.media" :src="article.media" />
        <h1  v-if="article">{{article.title}}</h1>
        <main class="content" v-if="article" v-html="article.content" />
        <a href="#" class="btn sml" @click.prevent="$router.go(-1)">Tillbaka</a>
    </article>
</template>

<script>

export default {
    name: 'fullprogramarticle',
    data(){
        return {
            tellMore: false
        }
    },
    computed: {
        article(){
            return this.$store.getters.programInfo(this.$route.params.program);
        }
    }
}
</script>

<style lang="scss">
@import '../../scss/variables';

.full-article {
    background: #eee;

    .back {
        position: absolute;
        background: black;
        top: 1rem;
        left: 1rem;
        font-size: .8rem;
        padding: .25rem;
        border-radius: $radius;
        color: rgba($color: #FFF, $alpha: .8);
        text-decoration: none;
    }

    img {
        width: 100%;
    }

    h1 {
        padding: 1rem;
        line-height: 1.6rem;
    }

    .ingress {
        padding: 1rem;
        margin: 0;
    }

    main {
        padding: 1rem;

        h2 {
            font-size: 1.2rem;
            margin: 2rem 0 .25rem 0;
        }

        ul {
            list-style: square !important;
            padding: 1rem 2rem;

            li {
                padding: 0 .75rem;
                margin: 0;

                p {
                    margin: 0;
                    padding: 0;
                    font-style: italic;
                    font-size: .8rem;
                }
            }
        }
    }
    
    .btn {
            margin: 1rem;
    }
}
</style>
